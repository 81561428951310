<template>
  <v-dialog v-model="dialog" :max-width="550" @keydown.esc="close" eager>
    <v-card max-width="550" :loading="advanced_search.search_busy" elevation="2">
      <v-subheader class="body-1 blue--text">Keyword Search <v-divider inset></v-divider></v-subheader>
      <v-card-text>
        <v-row dense>
          <!-- dates -->
          <v-col cols="6">
            <v-menu ref="date1" v-model="advanced_search.date1" :close-on-content-click="false"
              :return-value.sync="advanced_search.start" transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field class="shrink" :rules="[rules.less_than(advanced_search.end)]"
                  v-model="advanced_search.start" label="Start Date" append-icon="event" readonly v-bind="attrs"
                  v-on="on">
                </v-text-field>
              </template>
              <v-date-picker v-model="advanced_search.start" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="advanced_search.date1 = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.date1.save(advanced_search.start)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu ref="date2" v-model="advanced_search.date2" :close-on-content-click="false"
              :return-value.sync="advanced_search.end" transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field class="shrink" :rules="[rules.greater_than(advanced_search.start)]"
                  v-model="advanced_search.end" label="End Date" append-icon="event" readonly v-bind="attrs" v-on="on">
                </v-text-field>
              </template>
              <v-date-picker v-model="advanced_search.end" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="advanced_search.date2 = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.date2.save(advanced_search.end)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <!-- expression -->
        <v-row dense>
          <v-col cols="12">
            <v-text-field v-model="advanced_search.expression"
                          label="Expression"
                          ref="exp"
                          flat counter="20" maxlength="20"
                          @keydown.enter="query"
                          :hint="advanced_search.expression[0] === '#' ? 'Brix Search' : 'Search descriptions, companies and components.'"
                          persistent-hint
                          clearable
                          dense></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-subheader class="body-1 blue--text">Category Search <v-divider inset></v-divider></v-subheader>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <!-- category search -->
            <v-select v-model="category_tags" :items="category_items" label="Category" hide-details ref="category" dense clearable>
               <template v-slot:selection="{ item }">
                 <v-chip label small color="blue" class="white--text"><v-icon left>mdi-label</v-icon>{{ item }}</v-chip>
               </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <!-- category search -->
              <v-select v-model="subcategory_tags" :items="subcategory_items" label="Keywords" hide-details multiple ref="subcategory" dense clearable>
                <template v-slot:selection="{ item, index }">
                  <v-chip label small color="blue" class="white--text" v-if="index <= 2"><v-icon left>mdi-label</v-icon>{{ item }}</v-chip>
                  <span v-if="index === 3" class="grey--text text-caption">(+{{ subcategory_tags.length - 3 }} others)</span>
                </template>
              </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions style="background-color:#f1f1f4;">
        <v-btn text color="error" @click="reset_advanced_search">Reset</v-btn>
        <v-spacer></v-spacer>
        <span class="ml-4 overline indigo--text text--darken-2">{{advanced_search.results}}</span>
        <v-spacer></v-spacer>
        <v-btn outlined @click="query()" color="success" v-if="can_i_filter()" text :loading="advanced_search.search_busy">Filter</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import {
  EventBus
} from '../components/EventBus.js'
export default {
  name: "AdvancedSearchDialog",
  props: [
    // 'categories'
  ],
  watch: {
    category_tags() { this.$refs.category.setMenuIndex(0) },
    subcategory_tags() { this.$refs.subcategory.setMenuIndex(0) },
    specific_tags() { this.$refs.specific.setMenuIndex(0) }
  },
  data() {
    return {
      dialog: false,
      category_tags: [],
      category_items: ['Beverage','Food','Powder Blend','Other','Uncategorized'],
      subcategory_tags: [],
      subcategory_items: ['Alcohol','Still Beverage','Carbonated','Coffee','Dairy','Alternative Protein','Juice','Tea','Other','Uncategorized','Flavored Spirit', 'Hard Seltzer', 'Still Cocktail', 'Sparkling Cocktail', 'Wine', 'Cider', 'Beer', 'Mixer', 'Belly Wash','Functional','Soft Drink', 'Energy', 'Sparkling', 'Cold Brew', 'Hot Brew', 'Flavored', 'With Milk', 'Latte / Cappuccino', 'Espresso', 'Sparkling'],
      rules: {
        less_than(end) {
          return v => v < end || 'Invalid Start Date'
        },
        greater_than(start) {
          return v => v > start || 'Invalid End Date'
        },
      },
      advanced_search: {
        expression: '',
        member: '',
        team: [],
        model: null,
        start: null,
        end: null,
        date1: false,
        date2: false,
        number: '',
        desc: '',
        uses: '',
        results: '',
        search_busy: false,
        result_count: 0,
      },
    }
  },
  methods: {
    can_i_filter() {
      if((this.category_tags?.length >= 1) || (this.subcategory_tags?.length) >= 1 || (this.specific_tags?.length >= 1)) {
        return true
      } else {
        return this.advanced_search.expression?.length == 0 ? false : true
      }
    },
    create_pid_from_array(data) {
      return data.map(p => {
        return {
          fid: `${p.number}${p.sequence}-${p.variant.toString().padStart(2, "0")}`,
          ...p
        }
      })
    },
    query() {
      if (this.advanced_search.expression == null) return
      this.advanced_search.search_busy = true
      axios.post(`${this.$store.getters.NODEURL}/formula/archive/search`, {
          expression: this.advanced_search.expression,
          start: this.advanced_search.start,
          end: this.advanced_search.end,
          cat: this.category_tags === '' ? null : this.category_tags,
          sub: this.subcategory_tags.length === 0 ? null : this.subcategory_tags.join(','),
          // spec: this.specific_tags.length === 0 ? null : this.specific_tags.join(','),
        }, {
          withCredentials: true
        })
        .then((response) => {
          let p = response.data
          if (p.results.length > 0) {
            let res = this.create_pid_from_array(p.results)
            this.$emit('update', res)
            this.advanced_search.result_count = p.total
            this.advanced_search.results = `${p.total} record${p.total > 1 ? 's':''}`
          } else {
            this.advanced_search.results = `No Results`
          }
        })
        .catch((err) => {
          console.error(err)
          EventBus.$emit('error', {
            message: 'Failed to Retrieve Formulas - 23.1'
          })
        })
        .finally(() => {
          this.advanced_search.search_busy = false
        })
    },
    open() {
      this.reset_advanced_search()
      this.dialog = true
      this.$nextTick(() => {
       setTimeout(() => { this.$refs.exp.focus() }, 100)
      })
    },
    close() {
      this.dialog = false
    },
    reset_advanced_search() {
      let now = new Date()
      this.advanced_search.date1 = false
      this.advanced_search.date2 = false
      this.advanced_search.end = now.toISOString().substr(0, 10)
      this.advanced_search.start = "2000-01-01"
      this.advanced_search.results = ''
      this.advanced_search.expression = ''
      this.advanced_search.result_count = 0
      this.advanced_search.member = this.advanced_search.team[0]
      this.category_tags = ''
      this.subcategory_tags = []
    },
    max_date_search() {
      let now = new Date()
      this.advanced_search.date1 = false
      this.advanced_search.date2 = false
      this.advanced_search.start = '2010-01-01'
      this.advanced_search.end = now.toISOString().substr(0, 10)
    },
  },
}
</script>

<style scoped>
 .switch >>> .v-label.theme--light { margin-top:-1px; font-size:10pt; }
</style>